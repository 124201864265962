<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    data(){
        return {

        }
    },
    async beforeMount(){

    },
    beforeCreate(){
    },
    async mounted () {

      console.log("begin log app.vue")

      //打包
      await this.getOpenId();
      console.log("async mounted getOpenId over")

      // 本地调试
      // await this.getTest();
    },
    methods:{
      async getTest(){
        localStorage.setItem("wljptuser","obbSB0p_-fBohJd7HDJZ2Vhi39q0")
        // localStorage.setItem("wljptuser","obbSB0tQT7Aunv6UqZwxhZK851Ug")
        // localStorage.setItem("wljptuser","obbSB0gg0yyfBDfPZ80qtsGcsur8")
        // localStorage.setItem("wljptuser","obbSB0p_-fBohJd7HDJZ2Vhi39q0")
        // 酒店南京朗昇希尔顿酒店
        // localStorage.setItem("wljptuser","obbSB0o7lS0-YwSk41daKwjowgGY")
        Promise.all([this.getUserByOpenId()]).then(res=>{

        })

      },
      async resetUser(){
        Promise.all([this.getUserByOpenId()]).then(res=>{

        })

      },
      GetRequest() {
        let url = location.search;
        let theRequest = new Object();
        if (url.indexOf("?") != -1) {
          let str = url.substr(1);
          let strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
          }
        }
        return theRequest;
      },
      getUserByOpenId(){
        console.log("local has openid")
        return new Promise(resolve => {
          this.$http({
            url: "/zuul/cultural/user/"+ localStorage.getItem("wljptuser"),
            method: "get",
          }).then(res => {
            if(res.data.success){
              GlobalConfig.userInfo = res.data.data;
              if(res.data.data.info){
                localStorage.setItem("wljptinfoId",res.data.data.info.id);
                localStorage.setItem("wljptinfoType",res.data.data.info.type);
                localStorage.setItem("wljptinfoName",res.data.data.info.name);
              }
              localStorage.setItem("wljptuserName",res.data.data.name?res.data.data.name:res.data.data.wechatUser.nickname);
              localStorage.setItem("wljptphone",res.data.data.phone?res.data.data.phone:"");
              localStorage.setItem("wljptuser",res.data.data.openid);
              localStorage.setItem("wljptuserType",res.data.data.userType);
              localStorage.setItem("wljpttagIds",res.data.data.wechatUser.groupid);
              localStorage.setItem("wljptofficeIds",res.data.data.officeIds);
              resolve(true);
            }
          })
        })
      },
      getUserOpenId(){
        return new Promise(resolve => {
          this.$http({
            url: "/zuul/cultural/wechat/code/"+ getQueryString(window.location.href,"code"),
            method: "get",
          }).then(res => {
            if(res.data.success){
              localStorage.setItem("wljptuser",res.data.data.openid);
              GlobalConfig.userInfo = res.data.data;
              if(res.data.data.user){
                // localStorage.setItem("wljptuserName",res.data.data.user.name?res.data.data.user.name:res.data.data.user.wechatUser.nickname);
                localStorage.setItem("wljptphone",res.data.data.user.phone?res.data.data.user.phone:"");
                localStorage.setItem("wljptofficeIds",res.data.data.user.officeIds);

                if(res.data.data.user.name){
                  localStorage.setItem("wljptuserName",res.data.data.user.name);
                }else if(res.data.data.user.wechatUser){
                  localStorage.setItem("wljptuserName",res.data.data.user.wechatUser.nickname);
                }
                if(res.data.data.user.wechatUser){
                  localStorage.setItem("wljpttagIds",res.data.data.user.wechatUser.groupid);
                }

                if(res.data.data.user.info){
                  localStorage.setItem("wljptinfoId",res.data.data.user.info.id);
                  localStorage.setItem("wljptinfoType",res.data.data.user.info.type);
                  localStorage.setItem("wljptinfoName",res.data.data.user.info.name);
                }
              }
              resolve(true);
            }
          })
        })
      },
      getParam(para) {
        let s = window.location.href.replace("?","?&").split("&");
        for(let i=1;i<s.length;i++){
          if(s[i].indexOf(para + "=")==0){
            return s[i].replace(para + "=","")
          }
        }
        return false;
      },
      async getCode() {

        let code = this.getParam("code");
        localStorage.setItem("wljptcode", code);
        // const res = await getOpenId({ code });
        Promise.all([this.getUserOpenId()]).then(res=>{
          // window.location.reload();
        })
      },
      async getOpenId() {
        // let code = this.GetRequest()["code"];
        console.log("app mounted")
        if(!localStorage.getItem("wljptuser") || localStorage.getItem("wljptuser")=='undefined'){
          let code = this.getParam("code");
          if (!code) {
            // let href = GlobalConfig.host + "/#" + path;
            let href = window.location.href;
            let reDirectUrl = encodeURIComponent(href);
            window.location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + GlobalConfig.appId + "&redirect_uri="+
                reDirectUrl + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
          } else {
            await this.getCode();
          }
        }else{
          console.log("resetUser")
          await this.resetUser();
        }
      },
    }

  }
</script>
<style>
html{
    font-size: 13px !important;
}
body {
  font-size: 16px !important;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}

</style>
