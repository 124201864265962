import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';

Vue.use(Router);

const routes = [
    // 检查端
  {
    path: '*',
    redirect: '/map1'
  },
  {
    name: 'map1',
    component: () => import('./view/map/index2'),
    meta: {
      title: '区划地图'
    }
  },
  {
    name: 'map',
    component: () => import('./view/map'),
    meta: {
      title: '区划地图'
    }
  },
  {
    name: 'user',
    component: () => import('./view/user/info'),
    meta: {
      title: '个人中心'
    }
  },
  {
    name: 'rectify',
    component: () => import('./view/rectify/list'),
    meta: {
      title: '整改列表'
    }
  },
  {
    name: 'statistics',
    component: () => import('./view/statistics/index'),
    meta: {
      title: '统计上报'
    }
  },
  {
    name: 'list',
    component: () => import('./view/list/index'),
    meta: {
      title: '检查列表'
    }
  },
  {
    name: 'reportList',
    component: () => import('./view/list/reportListAll'),
    meta: {
      title: '企业上报列表'
    }
  },
  {
    name: 'vacci',
    component: () => import('./view/list/vacciList'),
    meta: {
      title: '核酸检测情况上报'
    }
  },
  {
    name: 'infoVacci',
    component: () => import('./view/list/infoVacci'),
    meta: {
      title: '企业疫苗接种'
    }
  },
  {
    name: 'healthInfo',
    component: () => import('./view/collect/checkList'),
    meta: {
      title: '酒店住客健康信息'
    }
  },
  {
    name: 'infoCompare',
    component: () => import('./view/collect/infoCompare'),
    meta: {
      title: '酒店住客健康信息比对'
    }
  },
    //企业端
  {
    name: 'undo',
    component: () => import('./view/rectify/qyUndo'),
    meta: {
      title: '待整改'
    }
  },
  {
    name: 'staffVacci',
    component: () => import('./view/list/staffVacci'),
    meta: {
      title: '疫苗接种上报'
    }
  },
  {
    name: 'venueList',
    component: () => import('./view/list/venueList'),
    meta: {
      title: '检查列表'
    }
  },
  {
    name: 'company',
    component: () => import('./view/company/form'),
    meta: {
      title: '企业档案'
    }
  },
    // 企业端客人健康信息采集
  {
    name: 'collect',
    component: () => import('./view/collect/list'),
    meta: {
      title: '健康信息采集'
    }
  },
//默认用户
  {
    name: 'info1',
    component: () => import('./view/user/index'),
    meta: {
      title: '个人信息'
    }
  },
  {
    name: 'register',
    component: () => import('./view/user/register'),
    meta: {
      title: '个人信息'
    }
  },
    // 测试

  {
    name: 'test',
    component: () => import('./view/map/index4'),
    meta: {
      title: '定位测试'
    }
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {

  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // console.log("get router")
  if(localStorage.getItem("wljptuser")){
    // console.log("get router has openid")
    Promise.all([getUserAllByOpenId()]).then(res=>{
      next();
    })
  }else{
    // console.log("get router does not have openid")
    next();
  }
});

const getUserAllByOpenId=()=>{
  return new Promise(resolve => {
    axios({
      url: "/zuul/cultural/user/"+ localStorage.getItem("wljptuser"),
      method: "get",
    }).then(res => {
      if(res.data.success){
        GlobalConfig.userInfo = res.data.data;
        if(res.data.data){
          localStorage.setItem("wljptuser",res.data.data.openid);
          localStorage.setItem("wljptreviewStatus",res.data.data.reviewStatus);
          if(res.data.data.officeIds){
            localStorage.setItem("wljptofficeIds",res.data.data.officeIds);
          }
          if(res.data.data.info){
            localStorage.setItem("wljptinfoId",res.data.data.info.id);
            localStorage.setItem("wljptinfoName",res.data.data.info.name);
          }
          if(res.data.data.wechatUser){
            localStorage.setItem("wljpttagIds",res.data.data.wechatUser.groupid);
            localStorage.setItem("wljptuserName",res.data.data.wechatUser.nickname);
          }
          if(res.data.data.name){
            localStorage.setItem("wljptuserName",res.data.data.name);
          }
          localStorage.setItem("wljptphone",res.data.data.phone?res.data.data.phone:"");
        }else{
          let localProps = Object.keys(localStorage);
          localProps.map(k=>{
            if(k!="user"){
              localStorage.removeItem(k);
            }
          })
        }
        resolve(true);
      }
    })
  })
}

export {
  router
};
